.liquidity {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  display: flex;
  align-items: center;
}

.title {
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 100px;
  color: #262626;
  margin-top: 100px;
}

.illustration {
  width: 40%;
  text-align: right;
}

.illustration img {
  max-width: 300px;
}

.info p {
  font-size: 16px;
}

.info {
  width: 60%;
}

@media (max-width: 860px) {
  .wrapper {
    flex-direction: column;
  }
  .info {
    width: 100%;
    padding: 0;
    order: 1;
  }
  .illustration {
    margin-bottom: 50px;
    order: 0;
  }
  .illustration {
    width: 70%;
    text-align: center;
  }
  .title {
    font-size: 24px;
    margin-bottom: 50px;
  }
}