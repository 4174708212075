.tokens {
  color: #333333;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

a.btnOpen {
  background-color: #0037ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px 30px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}


.info {
  width: 50%;
}

.infoFirst {
  composes: info;
  padding-right: 60px;
}

.infoSecond {
  composes: info;
  padding-left: 60px;
}

@media (max-width: 990px) {
  /* .infoFirst,
  .infoSecond {
    padding: 0;
  } */
  .tokens {
    padding: 0;
  }
 
  .tokensTitle {
    font-size: 18px;
  }
  .info p {
    font-size: 18px;
  }
}

@media (max-width: 860px) {
  .infoFirst, .infoSecond {
    padding: 0;
    width: 100%;
    order: 2;
  }
  .illustration {
    width: 100%;
    order: 1;
    margin-bottom: 50px;
  }
  .illustration img {
    max-width: 350px;
  }
  .tokens {
    flex-direction: column;
  }
}

/* NEW */

.tokensTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  margin-bottom: 50px;
  color: #262626;
}

.wrapper {
  display: flex;
  font-size: 16px;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 50px;
  border-bottom: 1px solid #eeeeee;
}

.wrapper:last-child {
  border: none
}

.item {
  width: 50%;
}

.second p a, .first p a {
  color: #0037ff !important;
}

.first {
  composes: item;
  padding-right: 20px;
}

.second {
  composes: item;
  padding-left: 20px;
}

.illustration {
  text-align: center;
}

.illustration img {
  max-width: 400px;
}

@media (max-width: 990px) {
  .item {
    width: 100%;
  }
  .first {
    padding-right: 0;
  }
  .second {
    padding-left: 0;
  }
  .wrapper {
    flex-direction: column;
    padding: 0;
    border-bottom: none;
  }
  .img {
    order: 0;
    justify-content: center;
  }
  .text {
    order: 1;
    margin-bottom: 60px;
  }
  .illustration {
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
  }

  .big {
    max-width: 100%;
  }

  .illustration img {
    max-width: 100%;
  }

  .tokensTitle {
    font-size: 24px;
  }
}