.title {
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 100px;
  color: #262626;
  margin-top: 100px;
}

.dag, .not_blockchain {
  display: flex;
  padding-left: 20px;
  margin-bottom: 100px;
  color: #333333;
  align-items: center;
}

.illustration {
  width: 50%;
  text-align: center;
}

.illustration img {
  max-width: 500px;
}

.info p {
  font-size: 16px;
}

.info p a {
  color: #0037ff;
}

.info {
  width: 50%;
  padding-left: 60px;
}

.not_blockchain .info {
  padding-left: 0;
  padding-right: 60px;
}

@media (max-width: 860px) {
  .title {
    font-size: 24px;
  }
  .dag, .not_blockchain {
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
  }
  .info {
    width: 100%;
    padding: 0;
    order: 1;
  }
  .illustration {
    margin-bottom: 50px;
    order: 0;
  }
  .not_blockchain .info {
    padding-right: 0;
  }
  .illustration {
    width: 70%;
    text-align: center;
  }
}