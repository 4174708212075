.wrap {
  padding-top: 20px;
  color: #333333;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.button a {
  background-color: #0037ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 45px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
}

.header {
  padding-top: 20px;
  background-image: url("./img/bg2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 100%;
  position: relative;
}

.logo {
  margin-bottom: 20px;
}

.logo img {
  width: 150px;
}

.title {
  font-weight: bold;
  font-size: 58px;
  color: #333333;
}

.subTitle {
  font-size: 38px;
  line-height: 40px;
  color: #333333;
}

.titleWrap {
  text-align: center;
  margin-bottom: 60px;
}

.action {
  font-size: 18px;
  text-align: center;
  color: #595959;
  margin-top: 50px;
  margin-bottom: 150px;
  color: #333333;
  padding-bottom: 50px;
}

.action a {
  color: #0037ff;
}

@media (max-width: 990px) {
  .header {
    background-image: url("./img/bg1-m.svg");
    background-position-x: center;
    background-size: contain;
    background-repeat: repeat-y;
  }
  /* .header::after {
    display: none;
  } */
  .title {
    font-size: 48px;
  }
  .subTitle {
    font-size: 32px;
  }
  .tokenItem {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .tokenItemTitle {
    padding: 0;
    font-size: 18px;
  }
  .tokenItemGraph {
    text-align: center;
  }
  .tokenItemTitle, .tokenItemGraph {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .tokenItem img {
    width: 100%;
  }
  .tokenItem svg {
    width: 100%;
  }
  .button {
    justify-content: center;
  }
  .title {
    font-size: 36px;
  }
  .subTitle {
    font-size: 24px;
  }
  .action {
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 18px;
    line-height: initial;
  }
  .logo {
    margin-top: 20px;
  }
  .logo img {
    width: 40%;
  }
}