.itemWrap{
    max-width: 800px;
    color: #333333;
    border: 1px solid #dcdde1;
    margin: 20px;
    padding: 12px 16px;
  }
  
  .itemName{
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .itemCurrent{
    text-align: right;
    font-size: 16px;
    padding-right: 10px;
  }

  .secondInfo{
    text-align: right;
  }

  @media (max-width: 700px){
    .itemWrap{
      padding: 10px;
      margin-left: 0;
      margin-right: 0;
    }
    .itemName{
      font-size: 14px;
    }
  }
  
  @media (max-width: 460px){
    .itemWrap{
      padding: 10px;
    }
    .itemName{
      font-size: 14px;
    }
  }

  @media (max-width: 576px){
    .itemCurrent{
      text-align: left;
    }
    .secondInfo{
      text-align: left;
    }
  }