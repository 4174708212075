.title {
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  margin-bottom: 50px;
  color: #262626;
}

a.btnOpen {
  background-color: #0037ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

.name {
  font-size: 20px;
  font-weight: bold;
  padding: 5px 0;
}

.item {
  text-align: center;
  margin-bottom: 40px;
}

.wrap {
  margin-bottom: 100px;
}